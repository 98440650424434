import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  if (posts.length === 0) {
    return (
      <Layout>
        <SEO title="Nos actualités" description="Trouvez toutes les actualités de Air water System." />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Nos actualités" description="Trouvez toutes les actualités de Air water System." />
      <section className="section">
        <div className="container">
          <h1 className="title">Nos actualités</h1>
          {posts.map(post => {
            const title = post.node.frontmatter.title || post.node.fields.slug

            return (
              <article
                className="post-list-item mb-6"
                itemScope
                itemType="http://schema.org/Article"
              >
                <h2 className="title">
                  <Link to={post.node.fields.slug}>
                    {title}
                  </Link>
                </h2>
                <p className="subtitle">{post.node.frontmatter.date}</p>
                <div className="columns">
                  <figure className="column is-2 has-text-centered" width="256">
                    <img className="blog-preview" src={post.node.frontmatter.featuredimage}></img>
                  </figure>
                  <p className="column">
                    {post.node.frontmatter.description}
                    <br/>
                    <Link to={post.node.fields.slug}>
                      Lire la suite &#8594;
                    </Link>
                  </p>
                </div>
              </article>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query Blog{
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            date(formatString: "DD MMMM YYYY", locale: "fr")
            description
            featuredimage
          }
        }
      }
    }
  }
`